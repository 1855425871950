<template>
  <a-spin :spinning="spinning">
    <div>
      <div class="MemberImport"  v-show="show1">
        <a-button type="primary" @click="add" style="margin-bottom: 10px" >
          新增导入
        </a-button>
        <!--  会员excel列表-->
        <a-table :columns="columns" :data-source="memberExcel" :rowKey="(record,index)=>{return index}"  position="bottom" :pagination="false"

        >
          <span slot="operate" slot-scope="text,item">
      <a-button type="link" @click="Check(item.id,item.excelName,item.createdTime)">查看</a-button>
    </span>
        </a-table>
        <div style="display: flex;justify-content: flex-end;right: 62px;bottom: 1rem;margin-top: 10px">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="paginations" />
        </div>
      </div>


      <!--导入会员excel-->
      <div class="memberAdd" v-show="show2">

        <!--  导入excel-->
        <div class="uploads">
          <div>
            <h3>选择导入文件</h3>
          </div>
          <a-divider />
          <div style="display: flex;align-items: center;">
            选择文件 &nbsp;
            <a-input v-model="excelName"  placeholder="请选择" style="width: 230px"  :disabled="true">
              <div slot="addonAfter">
                <input class="upload_excel" type="file" @change="upload" accept=".xls,.xlsx" ref="addExcel" />
                <span style="margin-left: -40px;">选择</span>
              </div></a-input>
            <a-button type="primary" style="margin-left: 30px;margin-right: 10px"  @click="addExcel">确认导入</a-button>
            <a-button style="margin-right: 5px" @click="downMode">下载模板</a-button>
            <span style="color: #aba9a9">(医生:1001;护士:1002;医技:1003;药师:1004;学生:1005;其他医务从业者:1006;非医务从业者:1007)</span>
          </div>
        </div>

        <div class="excelPreview">
          <div style="display: flex;align-items: center">
            <h3>预览数据</h3>
            <a-button style="margin-left: 20px;margin-right: 20px" type="primary" @click="excelAddOk">自动注册</a-button>
            <a-tag color="blue" > <a-icon type="info-circle" theme="filled" /> 预览数据存在未注册人员，可点击此按钮自动注册，自动注册密码为111111</a-tag>
          </div>
          <a-divider />

          <!--    会员信息table-->
          <a-table :columns="columnsAdd" :data-source="Excel" rowKey="id"   :pagination="false"
          >
            <span slot="operate" slot-scope="text,record">
     <span v-html="record.status === 1 ? '已注册':'未注册'" :style="{'color': (record.status === 1 ? '#3d8cff':'#000000')}">{{record.status}}</span>
    </span>
          </a-table>
          <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
            <a-pagination v-model="columnsPage.current"
                          :total="columnsPage.total"
                          @change="PageChange" />
          </div>
          <!--    同步选项确认-->
          <div class="addOK" style="margin-top: 10px; display: flex;justify-content: flex-end;">
            <div style="margin-right: 10px" >
              <a-button style="margin: 0 15px 0 0"  @click="()=>{this.credit = true;this.getCaseTitles()}">
                同步到会议学分注册
              </a-button>
              <a-button style="margin: 0 15px 0 0"  @click="()=>{this.Pay = true;this.getCaseTitles()}">
                同步到会议报名缴费
              </a-button>
              <a-button style="margin: 0 15px 0 0"  @click="caseOks">
                同步到病例开放范围
              </a-button>
            </div>
            <a-button type="danger" @click="CancelHome">返回</a-button>
          </div>
        </div>
      </div>
      <!--  查看会员信息-->
      <div class="memberAdd" v-show="show3">

        <div>
          <div class="uploads">
            <div>
              <h3>导入记录</h3>
            </div>
            <a-divider />
            <h4>附件名称: &nbsp;{{PreviewName}}</h4>
            <h4>导入时间: &nbsp;{{times}}</h4>
          </div>

          <div class="excelPreview">
            <div style="display: flex;align-items: center">
              <h3>导入数据</h3>
              <a-button style="margin-left: 20px;margin-right: 20px" type="primary" @click="excelAddOk">自动注册</a-button>
              <a-tag color="blue" > <a-icon type="info-circle" theme="filled" /> 预览数据存在未注册人员，可点击此按钮自动注册，自动注册密码为111111</a-tag>
            </div>
            <a-divider />

            <!--    会员信息table-->
            <a-table :columns="columnsAdd" :data-source="Excel" rowKey="id"   :pagination="false"
            >
              <span slot="operate" slot-scope="text,record">
     <span v-html="record.status === 1 ? '已注册':'未注册'" :style="{'color': (record.status === 1 ? '#3d8cff':'#000000')}">{{record.status}}</span>
    </span>
            </a-table>
            <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 5px;margin-bottom: 10px">
              <a-pagination v-model="columnsPage.current"
                            :total="columnsPage.total"
                            @change="PreviewChange" />
            </div>
            <div style="display: flex;justify-content: flex-end;right: 62px;position: fixed;bottom: 3.5rem;z-index: 3;">
              <div style="margin-right: 10px" >
                <a-button style="margin: 0 15px 0 0"  @click="()=>{this.credit = true;this.getCaseTitles()}">
                  同步到会议学分注册
                </a-button>
                <a-button style="margin: 0 15px 0 0"  @click="()=>{this.Pay = true;this.getCaseTitles()}">
                  同步到会议报名缴费
                </a-button>
                <a-button style="margin: 0 15px 0 0"  @click="caseOks">
                  同步到病例开放范围
                </a-button>
              </div>
              <a-button type="danger" @click="Cancel">返回</a-button>
            </div>
          </div>
        </div>
      </div>

      <a-modal  v-model="credit" title="同步到会议学分注册" :dialogStyle="{ 'top': '30%'}" ok-text="确认" cancel-text="取消" @ok="creditOk">
        请选择会议:&nbsp;<a-select placeholder="选择会议"  style="width: 300px"  >
        <a-select-option v-for="item in options" :key="item.id" :value="item.id" @click="creditChange(item.id)" >{{item.title}}</a-select-option>
      </a-select>
      </a-modal>
      <a-modal v-model="Pay" title="同步到会议报名缴费" :dialogStyle="{ 'top': '30%'}" ok-text="确认" cancel-text="取消" @ok="payOk">
        请选择会议:&nbsp;<a-select placeholder="选择会议"  style="width: 300px"  >
        <a-select-option v-for="item in options" :key="item.id" :value="item.id"  @click="payChange(item.id)">{{item.title}}</a-select-option>
      </a-select>
      </a-modal>
      <a-modal v-model="caseShow" title="同步到病例开放范围" :dialogStyle="{ 'top': '30%'}" ok-text="确认" cancel-text="取消" @ok="caseListOk">
        请选择病例:&nbsp;<a-select placeholder="选择病例模板"  style="width: 300px"  >
        <a-select-option v-for="item in caseList"   :value="item.id"  @click="caseChange(item.id)">{{item.title}}</a-select-option>
      </a-select>
      </a-modal>

      <a-modal
          :title="modalTitle"
          :visible="spinningAdd"
          :footer="null"
          :maskClosable="false"
          @cancel="()=>this.spinningAdd=false"
          :dialogStyle="{ 'top': '30%'}"
      >
        <a-progress    :percent="percent" :status="exception"/>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import {
  getCaseTemplate,
  getCaseTitle,
  getExcelList,
  getMemberLists,
  postCases,
  postCredit,
  postMemberExcel,
  postMembers,
  postPay
} from "@/service/memberImport_api";

export default {
  name: "MemberImport",
  data(){
    return{
      options:[],
      spinning:false,
      Pay:false,
      caseShow:false,
      credit:false,
      show1:true,
      show2:false,
      show3:false,
      spinningAdd:false,
      exception:null,
      modalTitle:'',
      caseList:[],
      percent:0,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columnsPage:{
        total:0,
        current: 1,
        pageSize:10,
      },
      PreviewPage:{
        total:0,
        current: 1,
        pageSize:10,
      },
      memberExcel:[],
      Excel:[],
      memberName: [],
      PreviewExcel:[],
      columnsAdd:[
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
        },
        {
          title: '身份',
          dataIndex: 'type',
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
        },
        {
          title: '导入时间',
          dataIndex: 'createdTime',
        },
        {
          title: '注册状态',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      memberId:'',
      excelName:'',
      columns:[
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text,record,index) => `${index+1}`,
          width: '5%',
        },
        {
          title: '附件名称',
          dataIndex: 'excelName',
          width: '10%'
        },
        {
          title: '数据数量',
          dataIndex: 'excelNum',
          width: '10%'
        },
        {
          title: '导入时间',
          dataIndex: 'createdTime',
          width: '10%'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
          width: '8%'
        },
      ],
      ids: '',
      addId:'',
      payid:'',
      PreviewName:'',
      times : ''
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "会员导入管理")
  },
  mounted() {
    this.getExcelLists()
  },
  methods:{
    //获取会员excel
    async getExcelLists(pageNum){
      this.spinning = true
      const excel = await getMemberLists(pageNum)
      this.memberExcel = excel.data.ms
      this.pagination.total = excel.data.count
      this.spinning = false
    },
    //获取新增的会员excel列表
    async getMemberList(id,pageNum){
      this.spinning = true
      const member = await getExcelList(id,pageNum)
      this.Excel = member.data.ms
      this.columnsPage.total = member.data.count
      this.spinning = false
    },
    //会议标题
    async getCaseTitles(){
      const cases = await getCaseTitle()
      this.options = cases.data
    },
    //选择excel文件
    upload(){
      // const file = e.target.files[0]
      let inputDOM = this.$refs.addExcel;
      let file= inputDOM.files[0];
      this.excelName = file.name
    },
    //下载会员模板
    downMode(){
      window.open('https://download-1252497858.cos.ap-guangzhou.myqcloud.com/%E4%BC%9A%E5%91%98%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx')
    },
    percentShow(){
      this.percent=0
      let that = this
      let timer = setInterval(function() {
        that.percent++
        if (that.percent > 100) {
          clearInterval(timer)
          that.percent = 100
        }
      }, 100)
    },
    //确认导入excel
    async addExcel(){
      this.spinningAdd = true
      this.modalTitle = '正在导入'
      this.percentShow()
      let inputDOM = this.$refs.addExcel;
      let file= inputDOM.files[0];
      let formData = new FormData()
      formData.append('exclFile', file)
      const excel = await postMemberExcel(formData)
      if (excel.code===0){
        this.addId = excel.data
        await this.getMemberList(this.addId,1)
        await this.getExcelLists()
        this.spinningAdd = false
        this.$message.success('上传成功')
      }
    },
    //选择同步状态
    //确认导入会员信息
    async excelAddOk(){
      this.spinningAdd = true
      this.modalTitle = '正在自动注册'
      this.percentShow()
        const data = {
          ids : this.show2 ===true?this.addId:this.PreviewId
        }
        const excel = await postMembers(data)
        if (excel.code === 0){
          await this.getMemberList(this.show2 ===true?this.addId:this.PreviewId,1)
          this.excelName = ''
          this.$message.success('导入成功')
          this.spinningAdd = false
        }else {
          this.$message.error('手机号重复' + excel.message)
          this.spinningAdd = false
        }

    },
    //点击病例开放范围
    async caseOks(){
      this.caseShow =true
      const Info = await getCaseTemplate()
      this.caseList = Info.data
    },
    //分页
    //excel信息分页跳转
     PageChange(){
       this.getMemberList(this.addId,this.columnsPage.current)
    },
     paginations(){
       this.getExcelLists(this.pagination.current)
    },
     PreviewChange(){
       this.getMemberList( this.PreviewId,this.columnsPage.current)
    },

    //同步学分
    async creditOk(){
      this.spinningAdd = true
      this.credit = false
      this.modalTitle = '正在同步'
      this.percentShow()
      const data = {
        // id : this.show2 ===true?this.addId:this.PreviewId,
        cid : this.ids
      }
      const ids = await  postCredit(data)
      if (ids.code === 0){
        this.$message.success('同步成功')
        this.spinningAdd=false
      }else {
        this.$message.error('同步失败' + ids.message)
        this.spinningAdd=false
      }

    },
    creditChange(e){
      this.ids =  e
    },
    //同步缴费
    async payOk(){
      this.spinningAdd = true
      this.Pay = false
      this.modalTitle = '正在同步'
      this.percentShow()
      const data = {
        // id : this.show2 ===true?this.addId:this.PreviewId,
        rid : this.payid
      }
      const ids = await  postPay(data)
      if (ids.code === 0){
        this.$message.success('同步成功')
        this.spinningAdd=false
      }else {
        this.$message.error('同步失败' + ids.message)
        this.spinningAdd=false
      }

    },
   //同步病例开放范围
    async caseListOk(){
      this.spinningAdd = true
      this.caseShow = false
      this.modalTitle = '正在同步'
      this.percentShow()
      const data = {
        id : this.show2 ===true?this.addId:this.PreviewId,
        tid: this.caseId
      }
      const ids = await  postCases(data)
      if (ids.code === 0){
        this.$message.success('同步成功')
        this.spinningAdd=false
      }else {
        this.$message.error('同步失败' + ids.message)
        this.spinningAdd=false
      }

    },
    payChange(e){
      this.payid = e
    },
    caseChange(id){
      this.caseId = id
    },

    //新增按钮
    add(){
      this.show1 = !this.show1;
      this.show2 = !this.show2;
      // this.$router.replace('/memberAdd')
    },
    //查看会员信息
    async Check(id,name,time){
      this.spinning = true
      this.show1 = false
      this.show2 = false
      this.show3 = true
      this.PreviewId = id
      this.PreviewName = name
      this.times = time
      await this.getMemberList(id,1)
      this.spinning = false
    },
    //返回
    Cancel(){
      this.show1 = true
      this.show2 = false
      this.show3 = false
      this.Excel = []
      this.columnsPage.total = 0
    },
    CancelHome(){
      this.show1 = true
      this.show2 = false
      this.show3 = false
      this.Excel = []
      this.columnsPage.total = 0
    },
  },
}
</script>
<style lang="scss" scoped>

.memberAdd{
  background-color: whitesmoke;
  .uploads{
    margin:  auto;
    padding: 15px;
    background-color: white;
    height: 150px;
    ::v-deep .ant-input-group-addon{
      padding: 0;
      width: 50px;
      text-align: left;
    }
    .upload_excel {
      z-index:1;
      width: 50px;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }}
  .excelPreview{
    margin: 10px auto 0;
    padding: 15px;
    background-color: white;
  }
}
</style>
